<template>
	<div>
		<div :class="`${title}-tab-details border`">
			<v-layout
				class="py-2 text-h6 px-3 grey lighten-4 fw-600 blue--text text--darken-4 text-uppercase"
			>
				<div class="my-auto">
					{{ title }}
				</div>
				<v-spacer></v-spacer>
				<v-btn color="blue darken-4 white--text mr-4" tile depressed @click="addCategory()">
					<v-icon left>mdi-plus</v-icon>
					Add
				</v-btn>
				<v-btn color="blue darken-4 white--text" tile depressed @click="saveCategory()">
					<!-- <v-icon left>mdi-plus</v-icon> -->
					Save
				</v-btn>
			</v-layout>
			<div class="px-6 py-4">
				<v-row v-for="(row, index) in subCategories" :key="index" class="mb-3 list-view">
					<v-col class="py-0" v-if="row.isEditable">
						<TextInput
							hide-top-margin
							hide-details
							:disabled="pageLoading"
							:loading="pageLoading"
							:id="row.id"
							:ref="`category-${index}`"
							:placeholder="title"
							v-model="row.subCategoryValue"
							@keydown.tab="addCategoryOnTabPress(index)"
							@keydown.delete="deleteCategoryOnBackspacePress(index)"
						></TextInput>
					</v-col>
					<v-col class="py-0 my-auto" v-else @click="toggleToEditableState(index)">
						<p class="px-3 mb-0">{{ row.subCategoryValue }}</p>
					</v-col>
					<v-col md="1" class="py-0">
						<v-btn
							v-if="row.isEditable"
							:disabled="subCategories.length === 1 && row.isEditable"
							class="white--text cursor-pointer"
							depressed
							color="red darken-4"
							tile
							@click="removeCategory(index, row)"
							min-width="30"
							max-width="30"
							width="30"
						>
							<v-icon>mdi-delete</v-icon>
						</v-btn>
					</v-col>
				</v-row>
			</div>
		</div>
	</div>
</template>
<script>
import TextInput from "@/view/components/TextInput";
export default {
	name: "category-setting",
	props: {
		title: {
			type: String,
			default: null,
		},
		isDisabled: {
			type: Boolean,
			default: true,
		},
	},
	data() {
		return {
			pageLoading: false,
			previouslyActiveInputFieldIndex: -1,
			subCategories: [
				{
					id: "bnbnmm1",
					subCategoryValue: "djkfhsdkjh",
					isEditable: false,
				},
				{
					id: "sdfsd2",
					subCategoryValue: "djkfhsdkjh",
					isEditable: false,
				},
				{
					id: "ghjgh3",
					subCategoryValue: "djkfhsdkjh",
					isEditable: false,
				},
				{
					id: "wqeew4",
					subCategoryValue: "djkfhsdkjh",
					isEditable: false,
				},
			],
		};
	},
	components: {
		TextInput,
	},
	methods: {
		addCategory(index) {
			if (this.subCategories.length) {
				this.$nextTick(() => {
					const element = this.$refs[`category-${index}`];
					const area = element[0].$el.querySelector("input");
					area.focus();
				});
			}
			this.subCategories.push({
				id: null,
				subCategoryValue: null,
				isEditable: true,
			});
		},
		removeCategory(index) {
			this.subCategories.splice(index, 1);
		},
		saveCategory() {
			this.subCategories.forEach((el) => {
				if (el.subCategoryValue) el.isEditable = false;
			});
			console.log(this.subCategories);
		},
		addCategoryOnTabPress(index) {
			if (index + 1 === this.subCategories.length) this.addCategory(index);
			const _line_items = this.subCategories.map((row) => {
				if (row.subCategoryValue) return { ...row, isEditable: false };
				return { ...row, isEditable: true };
			});
			this.subCategories = _line_items;
			this.subCategories[index + 1].isEditable = true;
		},
		deleteCategoryOnBackspacePress(index) {
			if (!this.subCategories[index].subCategoryValue && this.subCategories.length > 1) {
				this.removeCategory(index);
				this.subCategories[index - 1].isEditable = true;
				if (this.subCategories.length) {
					this.$nextTick(() => {
						const element = this.$refs[`category-${index - 1}`];
						const area = element[0].$el.querySelector("input");
						area.focus();
					});
				}
			}
		},
		toggleToEditableState(index) {
			this.subCategories[index].isEditable = true;
			if (this.previouslyActiveInputFieldIndex !== -1)
				this.subCategories[this.previouslyActiveInputFieldIndex].isEditable = false;
			this.previouslyActiveInputFieldIndex = index;
		},
	},
};
</script>
<style scoped>
.list-view:not(:last-child) {
	border-bottom: 0.1rem solid #eee;
	padding-bottom: 1rem;
}
.cursor-pointer:hover {
	cursor: pointer;
}
</style>
