<template>
	<div>
		<v-form
			ref="barcodeSetting"
			v-model="formValid"
			lazy-validation
			v-on:submit.stop.prevent="updateBarcode()"
		>
			<div :class="`${title}-tab-details border`">
				<v-layout
					class="py-2 text-h6 px-3 grey lighten-4 fw-600 blue--text text--darken-4 text-uppercase"
				>
					<div class="my-auto">
						{{ title }}
					</div>
					<v-spacer></v-spacer>
					<v-btn
						color="blue darken-4 white--text"
						:disabled="pageLoading"
						@click="updateBarcode()"
						tile
						depressed
					>
						Update
					</v-btn>
				</v-layout>
				<div class="px-6 py-4">
					<v-row>
						<v-col md="4">
							<label for="Prefix" class="btx-label">Prefix</label>
							<TextInput
								hide-top-margin
								hide-details
								:disabled="pageLoading"
								:loading="pageLoading"
								id="Prefix"
								placeholder="Prefix"
								v-model="prefix"
								:rules="[vrules.required(prefix, 'prefix')]"
								:class="{
									required: !prefix,
								}"
							></TextInput>
						</v-col>
						<v-col md="4">
							<label for="number-length" class="btx-label">Number Length</label>
							<TextInput
								hide-top-margin
								hide-details
								:disabled="pageLoading"
								:loading="pageLoading"
								id="number-length"
								placeholder="Number Length"
								v-model="number_length"
								:rules="[vrules.required(number_length, 'number length')]"
								:class="{
									required: !number_length,
								}"
							></TextInput>
						</v-col>
						<v-col md="4">
							<label for="next-number" class="btx-label">Next Number</label>
							<TextInput
								hide-top-margin
								hide-details
								:disabled="true"
								:loading="pageLoading"
								id="next-number"
								placeholder="Next Number"
								v-model="next_number"
								:rules="[vrules.required(next_number, 'next number')]"
								:class="{
									required: !next_number,
								}"
							></TextInput>
						</v-col>
					</v-row>
				</div>
			</div>
			<div class="border mt-5">
				<v-layout
					class="py-2 text-h6 px-3 grey lighten-4 fw-600 blue--text text--darken-4 text-uppercase"
				>
					<div class="my-auto">{{ title }} Remark</div>
					<v-spacer></v-spacer>
					<v-btn
						color="blue darken-4 white--text"
						:disabled="pageLoading"
						@click="updateBarcode()"
						tile
						depressed
					>
						Update
					</v-btn>
				</v-layout>
				<div class="px-6 py-4">
					<v-row>
						<v-col md="12">
							<TextAreaInput
								:disabled="pageLoading"
								:loading="pageLoading"
								id="remark"
								placeholder="Remark"
								v-model="remark"
							></TextAreaInput>
						</v-col>
					</v-row>
				</div>
			</div>
		</v-form>
	</div>
</template>
<script>
import { mapGetters } from "vuex";
import TextInput from "@/view/components/TextInput";
import TextAreaInput from "@/view/components/TextAreaInput";
import ApiService from "@/core/services/api.service";
import { SET_BARCODE_SETTING } from "@/core/services/store/config.module";
import { SET_ERROR, SET_MESSAGE } from "@/core/services/store/common.module";
import { filter } from "lodash";
export default {
	name: "Customer",
	data() {
		return {
			pageLoading: false,
			prefix: null,
			formValid: true,
			next_number: null,
			number_length: null,
			remark: null,
			db_type: {
				customer: 1,
				supplier: 2,
				product: 5,
				service: 15,
				"purchase-order": 31,
				quotation: 30,
				project: 12,
				expense: 48,
			},
		};
	},
	props: {
		type: {
			type: String,
			default: null,
		},
		title: {
			type: String,
			default: "Title",
		},
	},
	watch: {
		barcode_setting() {
			this.getBarcodeData();
		},
	},
	methods: {
		updateBarcode() {
			const _this = this;
			const formErrors = _this.validateForm(_this.$refs.barcodeSetting);
			if (formErrors.length) {
				_this.$store.commit(SET_ERROR, _this.errors.concat(formErrors));
				return false;
			}
			if (!_this.$refs.barcodeSetting.validate()) {
				return false;
			}
			_this.pageLoading = true;
			const payload = {
				type: this.get_type,
				number_length: this.number_length,
				next_number: this.next_number,
				prefix_string: this.prefix,
				remark: this.remark,
			};
			ApiService.setHeader();
			ApiService.patch(`setting/barcode`, payload)
				.then(() => {
					_this.$store.dispatch(SET_BARCODE_SETTING);
					this.$store.commit(SET_MESSAGE, [
						{
							model: true,
							message: `Success ! ${this.type} setting has been updated.`,
						},
					]);
				})
				.catch((error) => {
					_this.logError(error);
				})
				.finally(() => {
					_this.pageLoading = false;
				});
		},
		getBarcodeData() {
			//console.log(this.barcode_setting,"123");
			const data = filter(this.barcode_setting, (row) => {
				return row.type == this.get_type;
			});
			let obj = {};
			data.forEach((row) => {
				obj[row.field] = row.value;
			});
			this.prefix = obj.prefix_string;
			this.next_number = obj.next_number;
			this.number_length = obj.number_length;
			this.remark = obj.remark;
		},
	},
	computed: {
		...mapGetters(["errors", "barcode_setting"]),
		get_type() {
			return this.db_type[this.type];
		},
	},
	mounted() {
		this.getBarcodeData();
	},
	components: {
		TextInput,
		TextAreaInput,
	},
};
</script>
