<template>
	<div>
		<v-layout class="py-2">
			<div class="listing-heading">Country Setting</div>
			<v-spacer></v-spacer>
			<v-btn color="grey lighten-3" tile depressed class="mx-3" @click="goBack()">
				<v-icon small left>mdi-arrow-left</v-icon>
				Back
			</v-btn>
		</v-layout>
		<div>
			<div :class="`countrylist-tab-details border`">
				<v-layout
					class="py-2 text-h6 px-3 grey lighten-4 fw-600 blue--text text--darken-4 text-uppercase"
				>
					<div class="my-auto">
						Country List
						<span class="green--text ml-15"> Active (10) </span>
						<span class="red--text ml-5"> In-Active (05) </span>
					</div>
					<v-spacer></v-spacer>
					<v-btn color="blue darken-4 white--text" tile depressed> Update </v-btn>
				</v-layout>
				<div class="px-6 py-4">
					<perfect-scrollbar
						:options="{ suppressScrollX: true }"
						class="scroll custom-box-top-inner-shadow"
						style="max-height: 76vh; position: relative"
					>
						<div class="v-data-table bt-table country--height theme--light">
							<div class="v-data-table__wrapper-none">
								<table width="100%">
									<thead>
										<tr>
											<th width="50">#</th>
											<th>Country</th>
											<th width="300">Status</th>
											<th width="300">Number Limit</th>
										</tr>
									</thead>
									<tbody v-if="countryList.length">
										<tr v-for="(row, index) in countryList" :key="index">
											<td class="simple-table-td border-bottom">{{ index + 1 }}.</td>
											<td class="simple-table-td border-bottom">
												<div class="d-flex align-items-center">
													<div :class="row.country_code.toLowerCase()" class="vti__flag mr-2"></div>
													<span>{{ row.country_name }} {{ `+${row.phone_code}` }}</span>
												</div>
											</td>
											<td class="simple-table-td border-bottom">
												<span class="fw-600">
													<v-switch
														v-model="row.status"
														inset
														dense
														hide-details
														color="blue darken-4"
														class="ma-0 pa-0"
													></v-switch>
												</span>
											</td>
											<td class="simple-table-td border-bottom">
												<TextInput
													hide-top-margin
													hide-details
													type="number"
													:disabled="pageLoading"
													:loading="pageLoading"
													id="number-limit"
													placeholder="Number limit"
													v-model="row.phone_number_limit"
												></TextInput>
											</td>
										</tr>
									</tbody>
									<tfoot v-else>
										<template v-if="pageLoading">
											<tr>
												<td class="simple-table-td py-3" v-for="(di, did) in 4" :key="did">
													<v-skeleton-loader type="text" class="custom-skeleton"></v-skeleton-loader>
												</td>
											</tr>
											<tr>
												<td class="simple-table-td py-3" v-for="(di, did) in 4" :key="did">
													<v-skeleton-loader type="text" class="custom-skeleton"></v-skeleton-loader>
												</td>
											</tr>
											<tr>
												<td class="simple-table-td py-3" v-for="(di, did) in 4" :key="did">
													<v-skeleton-loader type="text" class="custom-skeleton"></v-skeleton-loader>
												</td>
											</tr>
										</template>
										<tr v-else>
											<td colspan="4" class="simple-table-td">
												<p class="m-0 row-not-found text-center font-weight-500 font-size-16">
													<img :src="$assetURL('media/error/empty.png')" class="row-not-found-image" />
													Uhh... There are no country at the moment.
												</p>
											</td>
										</tr>
									</tfoot>
								</table>
							</div>
						</div>
					</perfect-scrollbar>
				</div>
			</div>
		</div>
	</div>
</template>
<style src="@/assets/sass/sprite.scss" lang="scss"></style>
<script>
import ApiService from "@/core/services/api.service";
// import { SET_ERROR, SET_MESSAGE } from "@/core/services/store/common.module";
import TextInput from "@/view/components/TextInput";
// import allCountries from "@/core/plugins/country-list";
// import { map } from "lodash"
export default {
	name: "Customer",
	data() {
		return {
			pageLoading: false,
			countryList: [],
		};
	},
	methods: {
		updateCountry() {
			const _this = this;
			ApiService.setHeader();
			ApiService.patch(`setting/country`)
				.then(({ data }) => {
					console.log(data);
					this.countryList = data;
				})
				.catch((error) => {
					_this.logError(error);
				})
				.finally(() => {
					_this.pageLoading = false;
				});
		},
		getCountry() {
			const _this = this;
			this.pageLoading = true;
			ApiService.setHeader();
			ApiService.get(`setting/country`)
				.then(({ data }) => {
					console.log(data);
					this.countryList = data;
				})
				.catch((error) => {
					_this.logError(error);
				})
				.finally(() => {
					_this.pageLoading = false;
				});
		},
	},
	mounted() {
		//console.log({allCountries});
		this.getCountry();
		// this.countryList = map(allCountries,(row)=>{
		//     return {...row,number_limit:12,status:false}
		// })
	},
	components: {
		TextInput,
	},
};
</script>
