<template>
	<div>
		<v-layout class="py-2">
			<div class="listing-heading">Branding</div>
			<v-spacer></v-spacer>
			<v-btn color="grey lighten-3" tile depressed class="mx-3" @click="goBack()">
				<v-icon small left>mdi-arrow-left</v-icon>
				Back
			</v-btn>
		</v-layout>
		<div class="branding-tab-details border">
			<div class="py-2 text-h6 px-3 grey lighten-4 fw-600 blue--text text--darken-4">
				PDF HEADER & FOOTER
			</div>
			<div class="px-3">
				<v-row>
					<v-col md="3" class="my-auto">
						<span class="text-h6"> Quotation Header </span>
					</v-col>
					<v-col md="9">
						<BrandingUpload can-change v-model="quotation_header"></BrandingUpload>
					</v-col>
				</v-row>
				<v-row>
					<v-col md="3" class="my-auto">
						<span class="text-h6"> Purchase Header </span>
					</v-col>
					<v-col md="9">
						<BrandingUpload can-change v-model="po_header"></BrandingUpload>
					</v-col>
				</v-row>
			</div>
		</div>
	</div>
</template>
<script>
import BrandingUpload from "@/view/components/BrandingUpload";
export default {
	name: "Branding",
	data() {
		return {
			quotation_header: null,
			po_header: null,
		};
	},
	components: {
		BrandingUpload,
	},
};
</script>
