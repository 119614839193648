var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-form',{ref:"barcodeSetting",attrs:{"lazy-validation":""},on:{"submit":function($event){$event.stopPropagation();$event.preventDefault();return _vm.updateBarcode()}},model:{value:(_vm.formValid),callback:function ($$v) {_vm.formValid=$$v},expression:"formValid"}},[_c('div',{class:`${_vm.title}-tab-details border`},[_c('v-layout',{staticClass:"py-2 text-h6 px-3 grey lighten-4 fw-600 blue--text text--darken-4 text-uppercase"},[_c('div',{staticClass:"my-auto"},[_vm._v(" "+_vm._s(_vm.title)+" ")]),_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-4 white--text","disabled":_vm.pageLoading,"tile":"","depressed":""},on:{"click":function($event){return _vm.updateBarcode()}}},[_vm._v(" Update ")])],1),_c('div',{staticClass:"px-6 py-4"},[_c('v-row',[_c('v-col',{attrs:{"md":"4"}},[_c('label',{staticClass:"btx-label",attrs:{"for":"Prefix"}},[_vm._v("Prefix")]),_c('TextInput',{class:{
								required: !_vm.prefix,
							},attrs:{"hide-top-margin":"","hide-details":"","disabled":_vm.pageLoading,"loading":_vm.pageLoading,"id":"Prefix","placeholder":"Prefix","rules":[_vm.vrules.required(_vm.prefix, 'prefix')]},model:{value:(_vm.prefix),callback:function ($$v) {_vm.prefix=$$v},expression:"prefix"}})],1),_c('v-col',{attrs:{"md":"4"}},[_c('label',{staticClass:"btx-label",attrs:{"for":"number-length"}},[_vm._v("Number Length")]),_c('TextInput',{class:{
								required: !_vm.number_length,
							},attrs:{"hide-top-margin":"","hide-details":"","disabled":_vm.pageLoading,"loading":_vm.pageLoading,"id":"number-length","placeholder":"Number Length","rules":[_vm.vrules.required(_vm.number_length, 'number length')]},model:{value:(_vm.number_length),callback:function ($$v) {_vm.number_length=$$v},expression:"number_length"}})],1),_c('v-col',{attrs:{"md":"4"}},[_c('label',{staticClass:"btx-label",attrs:{"for":"next-number"}},[_vm._v("Next Number")]),_c('TextInput',{class:{
								required: !_vm.next_number,
							},attrs:{"hide-top-margin":"","hide-details":"","disabled":true,"loading":_vm.pageLoading,"id":"next-number","placeholder":"Next Number","rules":[_vm.vrules.required(_vm.next_number, 'next number')]},model:{value:(_vm.next_number),callback:function ($$v) {_vm.next_number=$$v},expression:"next_number"}})],1)],1)],1)],1),_c('div',{staticClass:"border mt-5"},[_c('v-layout',{staticClass:"py-2 text-h6 px-3 grey lighten-4 fw-600 blue--text text--darken-4 text-uppercase"},[_c('div',{staticClass:"my-auto"},[_vm._v(_vm._s(_vm.title)+" Remark")]),_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-4 white--text","disabled":_vm.pageLoading,"tile":"","depressed":""},on:{"click":function($event){return _vm.updateBarcode()}}},[_vm._v(" Update ")])],1),_c('div',{staticClass:"px-6 py-4"},[_c('v-row',[_c('v-col',{attrs:{"md":"12"}},[_c('TextAreaInput',{attrs:{"disabled":_vm.pageLoading,"loading":_vm.pageLoading,"id":"remark","placeholder":"Remark"},model:{value:(_vm.remark),callback:function ($$v) {_vm.remark=$$v},expression:"remark"}})],1)],1)],1)],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }